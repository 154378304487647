import React from "react"
import Slider from "react-slick";
import WiseTipCard from '../common/WiseTipCard';
import './wise-tip-slider.scss';

const WiseTipSlider = (props) => {
    const { data, location, user, fetchBookmarks, bookmarks } = props;
    const settings = {
        dots: true,
        arrows: false,
        className: 'pw-wt-slider',
        swipeToSlide: true,
        draggable: true,
        swipe: true,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            }
        ],
    };
    
    const wiseTipList = data.map((item,idx) => (
        <WiseTipCard
            key={`${item.title}_${idx}`}
            data={item} 
            page="home-page" 
            size="m4"
            location={location}
            user={user}
            bookmarks={bookmarks}
            fetchBookmarks={fetchBookmarks}
        />
    ))
    

    return (
        <div className="w3-row">
            <div className="pw-wise-tip-slider-block w3-hide-medium w3-hide-large" style={{paddingBottom: "25px"}}>
                <Slider {...settings}>
                    {wiseTipList}
                </Slider>
            </div>
            <div className="w3-hide-small" style={{display:'flex', flexWrap:'wrap'}}>
               {wiseTipList} 
            </div>
        </div>
    )
}

export default WiseTipSlider
