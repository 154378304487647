import React from 'react';

const Vimeo = (props) => {
    const { videoId } = props;
    if (!videoId) {
        return null;
    }

    
    return (
        <iframe
            src={`https://player.vimeo.com/video/${videoId}`}
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen>
        </iframe>
    );
}


export default Vimeo;
