import React from 'react';
import Modal from '../Modal';
import './video-modal.scss';
import Video from './Video';

const VideoModal = (props) => {
    const { openCls, closeVideoModal, data } = props;

    const handleClose = ()=>{
        document.documentElement.classList.remove("pw-no-scroll");
        closeVideoModal();
    }

    if (!data) {
        return null;
    }

    return (
        <Modal 
            openCls={openCls} 
            customClass="pw-video-modal"
            modalStyle={{ zIndex: 50 }}
        >
            <div className="w3-display-container">
                <div
                    className="pw-video-close"
                    onClick={() => handleClose()}
                >
                    <div className="pw-close-btn"/>
                </div>
            </div>
            <div className="pw-video-container">
                <Video data={data} />
            </div>
        </Modal>
    )
}

export default VideoModal