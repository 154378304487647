import React from 'react';
import PropTypes from 'prop-types';

const MainCard = (props) => {
    const { data, setVideoData } = props;
    if (!data) {
        return null;
    }
    let thumbnail = (data.thumbnail && data.thumbnail.sourceUrl) ? data.thumbnail.sourceUrl : null;
    let altText = (data.thumbnail && data.thumbnail.altText) ? data.thumbnail.altText : null;
    if(!thumbnail){
        thumbnail = `https://vumbnail.com/${data.videoId}.jpg`;
    }else if(!altText){
        altText = data.title;
    }
    return (
        <div className="pw-video-main-card" onClick={()=>setVideoData(data)}>
            <div className="pw-vmc-main-image">
                <div className="pw-vmc-play-btn" />
                <img loading="lazy" src={thumbnail}
                    alt={altText}
                    className="pw-vmc-thumbnail"
                />
            </div>
            <div className="pw-mc-title">
                {data.title}
            </div>
        </div>);
}

MainCard.propTypes = {
    data: PropTypes.shape({}).isRequired,
};

MainCard.defaultProps = {
    data: null
};

export default MainCard;
