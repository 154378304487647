import React from 'react';
import PropTypes from 'prop-types';
import Vimeo from './Vimeo';


const Video = (props) => {
    const { data } = props;
    if (!data) {
        return null;
    }
    return <Vimeo videoId={data.videoId} />;
}

Video.propTypes = {
    data: PropTypes.shape({})
};

Video.defaultProps = {
    data: null
};

export default Video;
