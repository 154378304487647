import React, { useState, useEffect, useContext } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./index.scss";
import VideoList from "../components/VideoList"
import WiseTipSlider from "../components/WiseTipSlider"
import Instagram from "../components/Instagram"
import Box from "../images/Box.png"
import Close from "../images/x.png"
import { api } from "../../utils";
import UserContext from "../context/UserContext";

const IndexPage = ({ data, location }) => {
  const userData = useContext(UserContext)
  const user = userData && userData.user;
  const { cms: { page: { acf_home_page: acf_home_page } } } = data;
  const [splashScreenStatus, setSplashScreenStatus] = useState(null);
  const [animationCheckbox, setAnimationCheckbox] = useState(null);

  const [wiseTipBookmarks, setWiseTipBookmarks] = useState([]);

  let splshTimer, timer1, timer2, timer3, timer4, timer5, timer6, timer7 = null;

  useEffect(() => {
    const splashScreenShow = window.localStorage.getItem('pw-splash-status');
    if (!splashScreenShow || splashScreenShow != 'HIDE') {
      // window.localStorage.setItem('pw-splash-status', 'HIDE');
      splshTimer = setTimeout(() => {
        setSplashScreenStatus("SHOW");
        showSplashScreenAnimations();
      }, 500);
    } else {
      setSplashScreenStatus("HIDE");
    }

    return function cleanup() {
      clearTimeout(timer6);
      clearTimeout(timer5);
      clearTimeout(timer4);
      clearTimeout(timer3);
      clearTimeout(timer2);
      clearTimeout(timer1);
      clearTimeout(splshTimer);
    };
  }, []);

  useEffect(() => {
    if (user) {
      fetchWiseTipBookmarks();
    }
  }, [user]);

  const fetchWiseTipBookmarks = () => {
    api.get('/api/v1/user-bookmark/list/wise-tip')
    .then(resp => {
      if (resp && resp.success && resp.data && resp.data.length) {
        let bookmarks = [];
        resp.data.forEach(i => bookmarks.push(i.slug));
        setWiseTipBookmarks(bookmarks);
      } else {
        setWiseTipBookmarks([]);
      }
    });
  }

  const showSplashScreenAnimations = () => {
    const splashScreen = document.getElementById("splash-screen");
    if (!splashScreen) {
      return;
    }
    splashScreen.getElementsByClassName("pw-cloud-anim-1")[0].classList.add("w3-animate-right");
    splashScreen.getElementsByClassName("pw-cloud-anim-2")[0].classList.add("w3-animate-left");

    timer1 = setTimeout(() => {
      splashScreen.getElementsByClassName("pw-cloud-anim-3")[0].classList.remove("w3-hide");
      splashScreen.getElementsByClassName("pw-cloud-anim-3")[0].classList.add("w3-animate-right");

      timer2 = setTimeout(() => {
        document.getElementById("pw-intro-text-1").classList.remove("w3-hide");
        document.getElementById("pw-intro-text-1").classList.add("pw-fade-in");

        timer3 = setTimeout(() => {
          document.getElementById("pw-intro-text-1").classList.remove("pw-fade-in");
          document.getElementById("pw-intro-text-1").classList.add("pw-fade-out");

          timer4 = setTimeout(() => {
            document.getElementById("pw-intro-text-1").classList.add("w3-hide");
            document.getElementById("pw-intro-text-2").classList.remove("w3-hide");
            document.getElementById("pw-intro-text-2").classList.add("pw-fade-in");

            timer5 = setTimeout(() => {
              splashScreen.classList.add("pw-collapse");

              timer6 = setTimeout(() => {
                document.getElementById("pw-animation-show").classList.add("w3-hide");
              }, 5000);

            }, 5000);

          }, 2000);

        }, 2000);

      }, 500);

    }, 100);
  }

  useEffect(() => {
    if (animationCheckbox) {
      localStorage.setItem('pw-splash-status', 'HIDE')
    }
    if (animationCheckbox == false) {
      localStorage.setItem('pw-splash-status', 'SHOW')
    }
  }, [animationCheckbox])

  const animationDisabled = () => {
    setAnimationCheckbox(!animationCheckbox)
  }


  if (!splashScreenStatus) {
    return (
      <div className="pw-home-page">
        <Layout>
          <SEO
            title="Home"
            path={location.pathname}
          />
            <div className="w3-center w3-padding-32" style={{ minHeight: '100vH' }}>
              <div className="pw-loader" />
            </div>
        </Layout>
      </div>
    )
  }

  return (
    <div className="pw-home-page">
      <Layout>
        <SEO
          title="Home"
          path={location.pathname}
        />

        {(splashScreenStatus === "SHOW") && (
          <div id="splash-screen">
            <div className="pw-cloud-anim-1">
              <div className="pw-cloud-r-1" />
            </div>

            <div className="pw-cloud-anim-2">
              <div className="pw-cloud-l-1" />
              <div className="pw-cloud-l-2" />
            </div>

            <div className="pw-cloud-anim-3 w3-hide">
              <div className="pw-cloud-r-2" />
              <div className="pw-cloud-r-3" />
            </div>

            <div style={{ position: 'absolute', width: '100%' }}>
              <h1 id="pw-intro-text-1" className="w3-center w3-padding w3-padding-64 w3-hide">
                It takes a village <br />
                to raise a parent.
              </h1>

              <h1 id="pw-intro-text-2" className="w3-center w3-padding w3-padding-64 w3-hide">
                ParentWise is a community movement<br />
                to nurture a generation of confident parents<br />
                and those involved in the future of our children.
              </h1>
            </div>
          </div>
        )}

        <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
          <div className="w3-row pw-home-video">
            <div className="pw-video-bg" />
            <div className="pw-video-pattern-top" />
            <VideoList data={acf_home_page.videoBlock} />
          </div>

          <div className="pw-top-banner">
            <h2 className="w3-center pw-title">An exciting journey awaits.</h2>
            <div className="pw-elephant-on-path" />
            {!user && <Link to="/auth" className="pw-journey-home-page" />}

            <div className="pw-home-link pw-wise-journey-link">
              <div className="pw-home-ring">
                <Link to="/wise-journey">
                  <div className="pw-home-ring-text">
                    <h3>EXPLORE OUR WISEJOURNEYS</h3>
                    <p>Experience a special curriculum designed by parents for parents.</p>
                  </div>
                </Link>
              </div>
            </div>

            <div className="pw-home-link pw-wise-tips-link">
              <div className="pw-home-ring">
                <Link to="/wise-tips">
                  <div className="pw-home-ring-text">
                    <h3>SEARCH WISETIPS</h3>
                    <p>Bring forth the best in children with our free evidence-based tips.</p>
                  </div>
                </Link>
              </div>
              <div className="pw-grass" />
            </div>

            <div className="pw-home-link pw-super-honestly-link">
              <div className="pw-home-ring">
                <Link to="/super-honestly">
                  <div className="pw-home-ring-text">
                    <h3>READ SUPERHONESTLY</h3>
                    <p>Learn from the shared wisdom of many on one collective platform.</p>
                  </div>
                </Link>
              </div>
              <div className="pw-elephant-icon" />
              <div className="pw-elephant-icon right" />
            </div>
          </div>
        </div>




        <div className="w3-row pw-instagram-stories">
          <h2 className="w3-center">FOLLOW US ON INSTAGRAM</h2>
          <Instagram />
          {/* <p className="w3-padding-32 w3-center">
            Instagram stories goes here..
          </p> */}
        </div>

        <div className="w3-row pw-tips-of-the-week">
          <h2 className="w3-center pw-tip-title">Tips of the Week</h2>

          <div className="w3-content">
            <WiseTipSlider
              data={acf_home_page.weekTips}
              location={location}
              user={user}
              bookmarks={wiseTipBookmarks}
              fetchBookmarks={fetchWiseTipBookmarks}
            />
          </div>
        </div>
        {(splashScreenStatus === "SHOW") && (
          <div className="pw-animation-container">
            <div id="pw-animation-show" className="pw-animation-show" >
              <div className="pw-animation-content w3-center w3-padding-16">
                <div>
                  <img src={Box} onClick={animationDisabled} />
                  {animationCheckbox === true ? <img src={Close} className="pw-animation-close" onClick={animationDisabled} /> : null}
                  <label className="w3-margin-left">
                    Do not play animation again
                  </label>
                </div>
              </div>
            </div>
          </div>
        )}

      </Layout>
    </div >
  )
}

export const query = graphql`
{
  cms {
    page(id: "home-page", idType: URI) {
      title
      slug
      acf_home_page {
        videoBlock {
          title
          videoId
          thumbnail {
            altText
            sourceUrl
          }
        }
        weekTips {
          ... on cms_WiseTip {
            title
            slug
            status
            acf_wise_tip {
              type
              tip
              themeColor
              description
              fieldGroupName
            }
            ageStages {
              nodes {
                id
                slug
                name
                parentId
                description
              }
            }
            domains {
              nodes {
                id
                slug
                name
                parentDatabaseId
                description
              }
            }
            contexts {
              nodes {
                id
                name
                slug
                parentId
              }
            }
          }
        }
      }
    }
  }
}
`

export default IndexPage
