import React, { useState } from 'react';
import Slider from 'react-slick';
import MainCard from './MainCard';
import VideoCard from './ListCard';
import './video-list.scss';
import VideoModal from '../common/VideoModal';

const VideoList = (props) => {
    const { data } = props;
    const [videoData,setVideoData] = useState(null);
    const [showVideo, setShowVideo] = useState('w3-hide');

    const handleVideoModal = (data)=>{
        setVideoData(data);
        setShowVideo('w3-show');
    }
    
    const closeVideoModal = ()=>{
        setVideoData(null);
        setShowVideo('w3-hide');
        document.body.classList.remove("pw-no-scroll");
    }

    const slideSettings = {
        dots: true,
        arrows: false,
        autoplay: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        className: 'pw-video-slider',
        swipeToSlide: true,
        draggable: true,
        swipe: true,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            }
        ],
    };

    if (!data) {
        return <h4>Loading...</h4>
    }

    return (
        <div className="w3-row pw-video-section">
            <div className="w3-content">
                <div className="w3-row-padding pw-video-list-container">
                    <div className="w3-row">
                        <div className="w3-center main-title">
                            VIDEO
                        </div>
                    </div>
                    <div className="w3-row w3-hide-small">
                        <div className={`w3-col ${data.length > 1 ? 'm8':'m12'}`}>
                            <div className={`pw-video-content-left ${data.length >= 2?'pw-vc-pad-right':'pw-vc-pad-none'}`}>
                                <MainCard
                                    data={data[0]}
                                    setVideoData={handleVideoModal}
                                />
                            </div>
                        </div>

                        {data.length > 1 && <div className="w3-col m4">
                            <div className="pw-video-content-right">
                                {data.slice(0, 3).map((video, idx) => {
                                    if (idx === 0) {
                                        return null;
                                    }
                                    return (
                                        <VideoCard
                                            key={video.title}
                                            data={video}
                                            setVideoData={handleVideoModal}
                                        />
                                    );
                                })}
                            </div>
                        </div>}
                    </div>
                    <div className="w3-row pw-video-slider-block w3-hide-medium w3-hide-large">
                        <Slider {...slideSettings}>
                            {
                                data.map(video => (
                                    <VideoCard
                                        key={video.title}
                                        data={video}
                                        setVideoData={handleVideoModal}
                                    />
                                ))
                            }
                        </Slider> 
                    </div>
                </div>
            </div>
            {
                videoData && <VideoModal
                                openCls={showVideo}
                                data={videoData} 
                                closeVideoModal={closeVideoModal} 
                            />
            }
        </div>);
}


export default VideoList;
