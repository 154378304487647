import React, { useState } from 'react';
import PWMarkdown from '../PWMarkdown';
import { Link } from "gatsby"
import { slice } from 'lodash';
import LikeIcon from '../../../images/icons/like.png';
import LikeIconActive from '../../../images/icons/like-active.png';
import ShareIcon from '../../../images/icons/share.png';
import SocialShare from '../../common/SocialButtons';
import { api } from '../../../../utils';
import './wise-tip-card.scss';

const WiseTipCard = (props) => {
    const { data, hideLink, size, page, location, inDetailPage=null, user, bookmarks, fetchBookmarks, customStyle, share, idx, setSelectedTip } = props;
    const description = data.acf_wise_tip.tip;

    if (!data || data.status !== 'publish') {
        return null;
    }

    const bookmarkTip = (slug) => {
        const fData = {
            type: 'wise-tip',
            slug
        }
        api.post('/api/v1/user-bookmark/toggle', fData)
        .then(resp => {
            if (resp && resp.success) {
                fetchBookmarks();
            } else {
                alert("Something went wrong. Please try again later!");
            }
        });
    }

    const getDomains = (data) => {
        if (!data || !data.nodes || !data.nodes.length) {
            return null;
        }
        let domains = [];
        data.nodes.forEach(item => {
            if (!item.parentDatabaseId) {
                return;
            }
            domains.push(item.name)
        });

        return domains.join(', ');
    }

    const getParentDomain = (data) => {
        if (!data || !data.nodes || !data.nodes.length) {
            return null;
        }
        let domains = [];
        data.nodes.forEach(item => {
            if (item.parentDatabaseId) {
                return;
            }
            domains.push(item.name)
        });

        return domains.join(', ');
    }

    const getCategories = (data, key) => {
        if (!data || !data.nodes || !data.nodes.length) {
            return null;
        }
        let categoryList = [];
        data.nodes.forEach(item => {
            categoryList.push(item[key])
        });

        return categoryList.join(', ');
    }

    const cardContent = (
        <div className="pw-wt-card">
            <div className="pw-wt-top-section">
                <div className="w3-row pw-wt-header" style={{ borderBottomColor: `${data.acf_wise_tip.themeColor}` }}>
                    <div className="pw-wt-title" style={{ color: `${data.acf_wise_tip.themeColor}` }}>
                        {getParentDomain(data.domains)}
                    </div>
                    <div className="pw-wt-subtitle" style={{ color: `${data.acf_wise_tip.themeColor}` }}>
                        {getDomains(data.domains)}
                    </div>
                </div>
                <div className="pw-wt-body" style={(data.acf_wise_tip.type === 'coreFinding') ? { paddingBottom: '15px' } : {}}>
                    <div className="w3-col s11">
                        <div className="pw-wt-body-title">
                            {data.title}
                        </div>
                        <div className="pw-wt-body-desc">
                            <PWMarkdown data={data.acf_wise_tip.tip} />
                        </div>
                    </div>
                    <div className="w3-col s1">
                        {!hideLink &&
                            <div className="pw-wt-right-chevron" />}
                    </div>
                </div>
            </div>
            <div className="w3-row pw-wt-footer">
                <div className="w3-col s9">
                    {(data.acf_wise_tip.type !== 'coreFinding') && 
                        <>
                            <div className="w3-row pw-wt-age">
                                {getCategories(data.ageStages, 'description')}
                            </div>
                            <div className="w3-row pw-wt-context">
                                {getCategories(data.contexts, 'name')}
                            </div>
                        </>
                    }
                </div>
                <div className="w3-col s3">
                    <div className={`pw-wt-buttons ${customStyle}`}>
                        {(user) && (
                            <button style={{ background: 'none', padding: '0px', border: 'none', outline: 'none', cursor: 'pointer' }}
                                onClick={(evt) => {
                                    evt.preventDefault();
                                    evt.stopPropagation();
                                    bookmarkTip(data.slug);
                                }}
                            > 
                                <img src={(bookmarks.indexOf(data.slug) !== -1) ? LikeIconActive : LikeIcon}
                                    className="pw-wt-btn-like"
                                />
                            </button>
                        )}
                        
                        <div className={share == false ? "w3-show" : "w3-hide"}>
                            <img src={ShareIcon} className="pw-wt-btn-share" onClick={(evt) => {
                                evt.preventDefault();
                                evt.stopPropagation();
                                setSelectedTip(idx);
                            }} />
                        </div>
                        {customStyle ? null :
                            <SocialShare data={share} handleSetOpenCls={(someData) => {setSelectedTip(someData)}} 
                                title={data.title} link={`${location.origin}/${(data.acf_wise_tip.type === 'coreFinding') ? 'core-finding' : 'wise-tip'}/${data.slug}`}
                                subjectTitle={`Check out this ${(data.acf_wise_tip.type === 'coreFinding') ? 'Core Finding' : 'tip'}!`} description={description}
                            />
                        }
                    </div>
                </div>

            </div>
        </div>
    );

    return (
        <div className={`w3-col ${size ? size : 'm12 l6'} pw-wise-tip-card-wrapper`}>
            <div className={`pw-wise-tip-card ${page ? page : ''}`}
                style={(data.acf_wise_tip.type === 'coreFinding') ? { border: `2px solid ${data.acf_wise_tip.themeColor}`} : {}}
            >
                {(!inDetailPage) ?
                    <Link to={`/${(data.acf_wise_tip.type === 'coreFinding') ? 'core-finding' : 'wise-tip'}/${data.slug}`}>
                        {cardContent}
                    </Link>
                    :
                    cardContent
                }
            </div>
        </div>
    )
}

export default WiseTipCard;