import React from 'react';
import PropTypes from 'prop-types';


const ListCard = (props) => {

    const { data, setVideoData } = props;
    if (!data) {
        return null;
    }
    let thumbnail = (data.thumbnail && data.thumbnail.sourceUrl) ? data.thumbnail.sourceUrl : null;
    let altText = (data.thumbnail && data.thumbnail.altText) ? data.thumbnail.altText : null;
    if(!thumbnail){
        thumbnail = `https://vumbnail.com/${data.videoId}.jpg`;
    }else if(!altText){
        altText = data.title;
    }

    return (
        <div className="w3-col" onClick={()=>setVideoData(data)}>
            <div className="pw-vc-side-card">
                <div className="pw-vsc-main-image">
                    <div className="pw-vsc-play-btn" />
                    <img loading="lazy" src={thumbnail}
                        alt={altText}
                        className="pw-vsc-thumbnail"
                    />
                </div>
                <div className="pw-vsc-title">
                    {data.title}
                </div>
            </div>
        </div>
    );
}

ListCard.propTypes = {
    data: PropTypes.shape({}).isRequired,
};

ListCard.defaultProps = {};


export default ListCard;
