import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import "./instagram.scss"
import _get from "lodash/get";

const InstagramPage = () => {
  const data = useStaticQuery(graphql`
    query InstagramQuery {
      allInstagramContent {
        nodes {
          media_type
          media_url
          thumbnail_url
          caption
          permalink
          localImage {
            publicURL

            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)

  let arrayOfInstaImages = _get(data, 'allInstagramContent.nodes')

  const settings = {
    className: "instagram-block",
    dots: false,
    arrows: false,
    swipeToSlide: true,
    draggable: true,
    swipe: true,
    responsive: [
        {
            breakpoint: 1900,
            settings: {
                slidesToShow: 3.4,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1.2,
            }
        },
    ],
  };

  const instagramList = arrayOfInstaImages.map((item, i) => {
    let imageData = item.media_url;
    if (item.media_type == 'VIDEO') {
      imageData = item.thumbnail_url;
      return (
        <div key={i} className="w3-col m4 s12">
          <a className="insta-link" href={item.permalink} target="_blank">
            <img loading="lazy" className="insta-img" src={imageData} />
          </a>
        </div>
      )
    }
    if (item.media_type != 'VIDEO' && item.localImage) {
      if (item.localImage.childImageSharp) {
        imageData = getImage(item.localImage);
        return (
          <div key={i} className="w3-col m4 s12">
            <a className="insta-link" href={item.permalink} target="_blank">
              <GatsbyImage className="insta-img" image={imageData} />
            </a>
          </div>
        )
      }
      imageData = item.localImage.publicURL;
      return (
        <div key={i} className="w3-col m4 s12">
          <a className="insta-link" href={item.permalink} target="_blank">
            <img loading="lazy" className="insta-img" src={imageData} />
          </a>
        </div>
      )
    }
  })

  return (
    <>
      <div className="w3-row w3-hide-small">
        {arrayOfInstaImages.length > 3 ?
          <Slider {...settings}> 
            {instagramList}
          </Slider>
        : <>{instagramList}</> }
      </div> 
      <div className="w3-row w3-hide-large w3-hide-medium">
        {arrayOfInstaImages.length > 1 ?
          <Slider {...settings}> 
            {instagramList}
          </Slider>
        : <>{instagramList}</> }
      </div> 
    </>
  ) 
}

export default InstagramPage;

